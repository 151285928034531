.bestsellers .section-heading {
  margin-bottom: 25px;
}

.bestsellers .section-heading h2 {
  font-family: "Inter Tight", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 38px;
  margin-bottom: 0px;
}

.bestsellers .section-heading p {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  color: #474747;
  margin-bottom: 0px;
}

.bestsellers .product-box {
  border: 1px solid rgba(34, 34, 34, 0.15);
  border-radius: 10px;
  cursor: pointer;
}

.bestsellers .product-box:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.bestsellers .product-box img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bestsellers .product-box .product-name {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}

.bestsellers .product-box .product-name h5 {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 0px;
}

/* FEATURES */
.feature-area {
  position: relative;
}

.feature-area .feature-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-shadow: 0px 24px 29px 0px rgb(0 0 0 / 8%);
  border-radius: 10px;
  z-index: 1;
}

.feature-area .feature-wrapper::before {
  position: absolute;
  top: 30px;
  left: 0;
  width: 90%;
  height: 100%;
  box-shadow: 0px 24px 29px 0px rgb(0 0 0 / 2%);
  border-radius: 10px;
  margin: auto;
  right: 0;
  z-index: -1;
}

.feature-area .feature-wrapper::after {
  position: absolute;
  top: 30px;
  left: 0;
  width: 95%;
  height: 100%;
  box-shadow: 0px 24px 29px 0px rgb(0 0 0 / 2%);
  border-radius: 10px;
  margin: auto;
  right: 0;
  z-index: -1;
}

.feature-area .feature-wrapper .single-feature-col {
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 auto;
  width: 33.333%;
}

.feature-area .feature-wrapper .single-feature-col .single-feature {
  padding: 30px;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.feature-area
  .feature-wrapper
  .single-feature-col
  .single-feature
  .feature-icon {
  width: 79px;
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 20px;
}

.feature-area
  .feature-wrapper
  .single-feature-col
  .single-feature
  .feature-content {
  width: calc(100% - 99px);
}

.feature-area
  .feature-wrapper
  .single-feature-col
  .single-feature
  .feature-content
  .title {
  font-family: "Mulish", sans-serif;
  font-size: 22px;
  color: #2e2e2e;
  font-weight: 600;
  margin: 0 0 3px;
}

.feature-area
  .feature-wrapper
  .single-feature-col
  .single-feature
  .feature-content
  .sub-title {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  color: #848484;
  font-weight: 400;
}

/* NEW ARRIVALS */
.new-arrivals .section-heading {
  margin-bottom: 25px;
}

.new-arrivals .section-heading h2 {
  font-family: "Inter Tight", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 38px;
  margin-bottom: 0px;
}

.new-arrivals .section-heading p {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  color: #474747;
  margin-bottom: 0px;
}

.new-arrivals .product-box {
  border: 1px solid rgba(34, 34, 34, 0.15);
  border-radius: 10px;
  cursor: pointer;
}

.new-arrivals .product-box:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.new-arrivals .product-box img {
  width: 100%;
  height: 220px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.new-arrivals .product-box .product-name {
  text-align: left;
  padding: 20px;
}

.new-arrivals .product-box .product-name h5 {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.new-arrivals .product-box .product-name p {
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  color: #474747;
  margin-bottom: 0px;
}

/* CATEGORIES */
.categories .categories-start {
  border: 1px solid rgba(34, 34, 34, 0.15);
  border-radius: 10px;
  cursor: pointer;
}

.categories .categories-start .categories-left {
  padding: 30px;
  padding-bottom: 10px;
}

.categories .categories-start .categories-left .categories-headings p {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  margin-bottom: 5px;
  color: #474747;
}

.categories .categories-start .categories-left .categories-headings h2 {
  font-family: "Inter Tight", sans-serif;
  font-weight: 700;
  font-size: 38px;
  margin-bottom: 20px;
}

.categories .categories-start .categories-left .categories-headings a {
  font-family: "Mulish", sans-serif;
  text-decoration: none;
  color: #474747;
  font-size: 16px;
}

.categories-start .categories-left .category-box {
  position: relative;
}

.categories-start .categories-left .category-box:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.categories-start .categories-left .category-box img {
  width: 100%;
  border-radius: 10px;
}

.categories-start .categories-left .category-box .category-name {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
}

.categories-start .categories-left .category-box .category-name h5 {
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  margin-bottom: 0px;
  font-weight: 700;
}

/* BANNERS */
.hero-slide-content {
  position: relative;
  z-index: 9;
  text-transform: capitalize;
  text-align: left;
}

.hero-slide-content .title-1 {
  font-family: "Inter Tight", sans-serif;
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #4a4a4a;
  line-height: 60px;
}

.hero-slide-content .title-2 {
  font-family: "Inter Tight", sans-serif;
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #4a4a4a;
}

.hero-slide-content .banner-subheading {
  font-family: "Inter Tight", sans-serif;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 10px;
  color: #4a4a4a;
  line-height: 40px;
}

.hero-slide-content h5 {
  font-family: "Inter Tight", sans-serif;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 30px;
  color: #4a4a4a;
}

.hero-slide-content .price {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 37px;
  color: #4a4a4a;
  display: block;
}

.view-collection {
  font-family: "Mulish", sans-serif;
  color: #fff;
  background-color: #3e3ef4;
  font-weight: 600;
  border-radius: 5px;
  padding: 15px 20px;
}

.carousel-item {
  height: fit-content;
}

.carousel-item img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.carousel-caption {
  height: 100%;
  display: flex;
  align-items: center;
}

.hero-slide-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.hero-slide-image img {
  max-width: 100%;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.carousel-control-next {
  top: 50%;
  right: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #000;
  box-shadow: 0 3px 25.5px 4.5px rgb(0 0 0 / 20%);
  background-color: #fff;
}

.carousel-control-prev {
  top: 50%;
  left: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #000;
  box-shadow: 0 3px 25.5px 4.5px rgb(0 0 0 / 20%);
  background-color: #fff;
}

.offcanvas-header {
  border-bottom: 1px solid #e2e9e1;
}

.offcanvas-body {
  font-family: "Mulish", sans-serif;
}

.offcanvas.offcanvas-end {
  width: 350px;
}

#align-center {
  align-items: center;
}

.instagram-wall .section-heading {
  margin-bottom: 25px;
}

.instagram-wall .section-heading h2 {
  font-family: "Inter Tight", sans-serif;
  font-weight: 700;
  font-size: 38px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.instagram-wall .section-heading a {
  font-family: "Inter Tight", sans-serif;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 0px;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 15px;
  color: #000;
}

.instagram-wall .section-heading a:hover {
  text-decoration: underline;
}

.instagram-wall .instagram-post {
  width: 100%;
  height: 100%;
}

.instagram-wall .instagram-post video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.instagram-wall .instagram-post img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category-box-grid {
  width: 100%;
  position: relative;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.category-box-grid:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.category-box-grid:hover .category-name .right-arrow {
  transform: translateX(5px);
}

.category-box-grid-center:hover .category-name .right-arrow {
  transform: translateX(5px);
}

.category-box-grid img {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  object-fit: cover;
}

.category-box-grid .category-name {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
}

.category-box-grid .category-name .right-arrow {
  margin-top: -2px;
}

.category-box-grid .category-name h5 {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 700;
}

.category-box-grid-center {
  width: 100%;
  position: relative;
}

.category-box-grid-center img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.category-box-grid-center .category-name {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
}

.category-box-grid-center .category-name h5 {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 700;
}

.categories-grid .section-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.categories-grid .section-heading h2 {
  font-family: "Inter Tight", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 38px;
  margin-bottom: 0px;
  position: relative;
  padding: 0 40px 15px;
  display: block;
  letter-spacing: 1px;
}

.view-all-link {
  font-family: "Mulish", sans-serif;
  text-decoration: none;
  color: #000;
  font-weight: 700;
  margin-bottom: 0px;
  cursor: pointer;
}

.view-all-link:hover {
  color: #3e3ef4;
}

.clearance-sale {
  padding-top: 40px;
  padding-bottom: 250px;
  background-image: url("../../assets/clearance-bg-2.webp");
  background-size: 100%;
  background-repeat: no-repeat;
}

.clearance-sale .left-text h2 {
  font-family: "Inter Tight", sans-serif;
  font-size: 38px;
  font-weight: 700;
  color: #fff;
}

.clearance-sale .left-text p {
  font-family: "Mulish", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin-top: 10px;
  color: #fff;
}

.sale-shop-button {
  background-color: #3e3ef4;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  padding: 10px 15px;
}

.rewards-background {
  background-image: url("../../assets/rewards-bg.webp");
  border-radius: 10px;
  padding: 66px 0 80px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.rewards-background .left-rewards h2 {
  font-family: "Inter Tight", sans-serif;
  font-size: 38px;
  font-weight: 700;
  color: #000;
}

.feature-icons {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
}

.feature-icons .features-section-one {
  text-align: center;
  margin-right: 35px;
}

.feature-icons .features-section-one:last-child {
  border-right: 0px solid transparent;
  margin-left: 35px;
}

.feature-icons .features-section-one h5 {
  font-family: "Inter Tight", sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
}

.feature-icons .border-middle {
  border-right: 1px solid #000;
}

.learn-more-button {
  background-color: #3e3ef4;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  padding: 10px 15px;
}

.rewards-right {
  display: flex;
  justify-content: center;
}
.onHover-product-box {
  height: 220px;
  padding: 20px 15px;
  min-height: 320px;
}

.onHover-label {
  margin-bottom: 0px;
  color: #3e3ef4;
  font-weight: 700 !important;
  font-family: "Mulish", sans-serif;
}

.onHover-content {
  margin-bottom: 0px;
  color: #2e2e2e;
  font-family: "Mulish", sans-serif;
}

.new-category-box {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  margin-bottom: 30px;
}
.new-category-box:hover {
  height: 30vh !important;
}

.new-category-box .new-category-image {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.new-category-box .new-category-image img {
  height: 230px;
  width: 100%;
  object-fit: cover;
  display: flex;
  border-radius: 10px;
  justify-content: center;
}

.new-category-box .category-name {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  border: 1px solid rgb(225, 225, 225);
}

.new-category-box .category-name h5 {
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  margin-bottom: 0px;
  font-weight: 700;
}

.new-category-box:hover .category-name .right-arrow {
  transform: translateX(5px);
}

.wholesale-banner {
  background-image: url("../../assets/Waterles.png") !important;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 60px;
  padding-bottom: 250px;
}

.wholesale-banner h1 {
  font-family: "Inter Tight", sans-serif;
  color: #000000b0;
  font-family: "Courier New", Courier, monospace;
  font-weight: 700;
  font-size: 50px;
  text-align: center;
}

.wholesale-details-form h2 {
  font-family: "Inter Tight", sans-serif;
  font-weight: 700;
}

.wholesale-details-form .submit-btn {
  width: max-content;
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 40px;
  margin-left: 12px;
  color: #fff;
  background-color: #000;
  border-radius: 5px;
}

.wholesale-details-form .submit-btn:hover {
  background-color: #005cb9;
  border-color: #005cb9;
  box-shadow: none;
}

.wholesale-bg-section {
  /* background-image: url("../../assets/wholesale-form-banner.webp"); */
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: cornsilk;
}

.wholesale-bg-section h2 {
  font-family: "Inter Tight", sans-serif;
  font-weight: 700;
}

.wholesale-bg-section .submit-btn {
  width: max-content;
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 40px;
  margin-left: 12px;
  color: #fff;
  background-color: #000;
  border-radius: 5px;
}

.wholesale-bg-section .submit-btn:hover {
  background-color: #005cb9;
  border-color: #005cb9;
  box-shadow: none;
}

#banner-3 {
  margin-bottom: 0px;
}

.wholesale-textbox {
  width: max-content;
  padding: 50px;
  background-color: #fff;
  margin-bottom: 100px !important;
  margin-top: 100px;
}

/* NEW CSS */
/* CATEGORY */
.categories-icon {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 45px;
  width: 100%;
  margin-inline: auto;
}
.categories-block {
  margin: 5px;
  max-width: 90px;
  min-height: 180px;
}
.categories-block-icon {
  border-radius: 50%;
  padding: 10px;
  position: relative;
  width: 90px;
  height: 90px;
  background-color: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
}
.categories-block-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}
.categories-block p {
  color: #0c0c0c;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0 !important;
  margin-top: 20px;
}
.categories-block:hover .categories-block-icon {
  box-shadow: 0 0 12px rgba(242, 211, 9, 0.815);
}
.categories-block:hover p {
  color: rgba(242, 211, 9, 1);
}
/* EXPLORE_ALL */
.explore-all {
  min-width: 300px;
  width: fit-content;
  border-radius: 40px;
  background-color: #252525;
  padding: 6px 6px 6px 12px;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
  margin: 0 auto 20px;
  border: 1px solid #252525;
}
.explore-all .explore-all-arrow {
  background-color: #fff;
  border-radius: 40px;
  height: 100%;
  padding: 5px 9px;
}
.explore-all .explore-all-arrow .explore-all-arrow-icon {
  color: #252525;
  width: 24px;
  height: 24px;
  margin-bottom: 2px;
}

.explore-all:hover {
  background-color: #fff;
  color: #252525;
  border: 1px solid #252525;
}
.explore-all:hover .explore-all-arrow {
  background-color: #252525;
}
.explore-all:hover .explore-all-arrow .explore-all-arrow-icon {
  color: #fff;
}

/* BESTSELLER */
.bestseller {
  width: 95%;
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.bestseller-block {
  margin: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* gap: 50px; */
  background: #f8f9fa;
  padding: 15px;
  width: 330px;
  transition: all 0.5s ease-in-out;
}
.up-radius {
  border-radius: 156px 156px 10px 10px;
}
.down-radius {
  border-radius: 10px 10px 156px 156px;
}
.bestseller-block-icon {
  border-radius: 50%;
  padding: 10px;
  position: relative;
  width: 300px;
  height: 300px;
  background-color: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
.bestseller-block-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
}
.bestseller-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 10px;
  width: 100%;
}
.bestseller-content p {
  color: #0c0c0c;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0 !important;
  font-family: "Inter Tight", sans-serif;
}
.bestseller-content .explore-all-arrow {
  background-color: #fff;
  border-radius: 22px;
  height: 100%;
  padding: 5px 9px;
}
.bestseller-content .explore-all-arrow .explore-all-arrow-icon {
  color: #252525;
  width: 18px;
  height: 18px;
  margin-bottom: 4px;
  cursor: pointer;
}

.bestseller-block:hover .explore-all-arrow {
  background-color: #252525;
}
.bestseller-block:hover .explore-all-arrow .explore-all-arrow-icon {
  color: #fff;
}

/* FEATURES */
.natural-container {
  width: 95%;
  min-height: 430px;
  position: relative;
  background: #f8f9fa;
  border-radius: 20px;
  margin: 40px auto;
  padding: 20px;
}
.natural-container .natural-heading {
  text-align: center;
  /* font-size: 22px; */
  font-weight: 600;
}
.natural-container .bg-image::before {
  content: "";
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 350px;
  height: 350px;
  background-image: url(/src/assets/icons/pngegg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.2;
  z-index: 0;
}
.feature-container-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 50px;
}
.feature-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 30px;
  margin-top: 12px;
}
.feature-block {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  /* margin-block: 15px; */
}
.feature-block img {
  width: 50px;
  height: 50px;
}

.feature-block h5 {
  color: #252525;
  font-weight: 500;
  margin-bottom: 0 !important;
  font-size: 18px;
}
.feature-block h6 {
  color: #25252581;
  font-weight: 400;
  font-size: 16px;
}

/* NEWS */
.news-container {
  display: flex;
  gap: 15px;
  width: 95%;
  min-height: 430px;
  position: relative;
  background: #f8f9fa;
  border-radius: 20px;
  margin: 40px auto;
  padding: 20px;
  flex-direction: row;
}
.news-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #f8f9fa;
  border-radius: 20px;
  padding: 20px;
  margin: 40px auto;
  width: 90%;
  position: relative;
  overflow: hidden;
}

.news-heading {
  position: relative;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  font-weight: 600;
  margin-right: 20px;
  max-width: 320px;
}

.news-heading h5 {
  color: #848484;
  margin-bottom: 5px;
}

.news-heading.hidden {
  opacity: 0;
  transform: translateX(-50px);
  pointer-events: none;
}

.news-scroll-container {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex: 1;
  scroll-behavior: smooth;
  margin-left: 20px;
  padding-bottom: 20px;
  transition: padding-left 0.5s ease-in-out;
}

.news-container-div {
  display: flex;
  gap: 15px;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.news-block {
  position: relative;
  cursor: pointer;
  background-color: #fff;
  border-radius: 15px;
  width: 230px;
  flex-shrink: 0;
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.news-block .img {
  width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 15px;
}

.news-block p {
  display: block;
  opacity: 0;
  padding: 4px 15px;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 20px);
  background-color: #1a1a1a;
  color: #f0f0f0cf;
  border: 1px solid #f0f0f0cf;
  border-radius: 15px;
  width: fit-content;
  margin-inline: auto;
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.glow-on-hover {
  width: 200px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 20px);
  opacity: 0;
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.news-block:hover .glow-on-hover {
  opacity: 1;
  transform: translate(-50%, 0);
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(45deg, #ff4d4d, #ffcc00, #33cc33, #0099ff);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 300%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 15s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.news-container-div.hidden-left .news-block {
  opacity: 0;
  transform: translateX(-50px);
}

/* Scrollbar Styling */
.news-scroll-container::-webkit-scrollbar {
  height: 12px;
}

.news-scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.news-scroll-container::-webkit-scrollbar-thumb {
  background-color: #1a1a1a;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.news-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* standard */
.standards-container {
  width: 95%;
  min-height: 230px;
  position: relative;
  background: #f8f9fa;
  border-radius: 20px;
  margin: 40px auto;
  padding: 30px 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.standards-container h3 {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 30px;
  color: #333;
  letter-spacing: 1px;
}

.standards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.standards-block {
  display: flex;
  gap: 20px;
  padding: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.standards-block:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.standards-block img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.standards-block:hover img {
  transform: scale(1.05);
}

.standards-text {
  display: block;
  max-width: 240px;
}
.standards-text1 {
  display: block;
  /* max-width: 240px; */
}
.standards-text h5 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #444;
}

.standards-text p ,.standards-text1 p{
  font-size: 0.95rem;
  color: #666;
  line-height: 1.5;
}

/* testimonial */
.testimonial1 {
  padding: 50px 0;
  background-color: #f8f9fa;
}

.testimonial1 h2 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.testimonial1 h6 {
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
}

.carousel-container {
  margin-top: 40px;
}

.item {
  display: flex;
  justify-content: center;
}

.card {
  max-width: 90%;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card:hover {
  transform: translateY(-5px);
}

.card-body {
  text-align: center;
}

.thumb {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  position: relative;
}

.thumb-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.profile-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.profile-tab img {
  margin-bottom: 10px;
}

.profile-tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 2px;
  background-color: #ddd;
}

.profile-tab .name {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
}

.font-weight-light {
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
}

.divider {
  width: 50px;
  height: 2px;
  background-color: #ddd;
  margin: 15px auto;
}

@media (max-width: 1024px) {
  .standards {
    grid-template-columns: repeat(2, 1fr);
  }

  .standards-block img {
    width: 90px;
    height: 90px;
  }
  .news-heading {
    max-width: 280px;
  }
}

@media (max-width: 768px) {
  .standards {
    grid-template-columns: 1fr;
  }

  .standards-block {
    flex-direction: column;
    text-align: center;
  }

  .standards-block img {
    width: 80px;
    height: 80px;
  }

  .standards-text {
    max-width: none;
  }
  .card {
    max-width: 100%;
    padding: 20px;
    min-height: 250px;
  }

  .thumb {
    width: 60px;
    height: 60px;
  }

  .thumb-img {
    width: 60px;
    height: 60px;
  }
  .font-weight-light {
    font-size: 0.9rem !important;
  }
  .news-heading {
    max-width: 250px;
  }
}

@media (max-width: 430px) {
  .font-weight-light {
    font-size: 0.8rem !important;
  }
  .news-container {
    flex-direction: column;
  }

  .categories-block {
    min-height: 150px;
  }
  .natural-container {
    width: 95%;
  }
  .natural-container .bg-image::before {
    width: 300px;
    height: 300px;
  }
  .feature-container-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline: 10px;
  }
  .feature-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 10px;
    margin-top: 12px;
  }
  .news-block {
    width: 85%;
  }
  .news-heading {
    max-width: 100%;
  }
  .news-scroll-container {
    margin-left: 0;
  }
  .standards {
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }
  .standards-block {
    padding: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: transparent !important;
    border-radius: 15px;
    box-shadow: none;
  }
  .standards-block:hover {
    transform: translateY(-0px);
    box-shadow: none;
  }

  .standards-block:hover img {
    transform: scale(1);
  }
  .standards-block img {
    width: 80px;
    height: 80px;
  }
  .standards-text {
    display: none;
  }
}
