.rewards-banner {
    background-image: url('../../assets/banners/rewards-banner.webp');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 80px;
    padding-bottom: 80px;
}

.rewards-text {
    background-color: rgba(255, 255, 255, 0.8);
    border: 3px solid #3C2415;
    padding: 20px 30px;
    margin-left: 100px;
}

.rewards-text h1 {
    font-family: 'Inter Tight', sans-serif;
    font-size: 50px;
    font-weight: 700;
}

.rewards-text p {
    font-family: 'Mulish', sans-serif;
    font-size: 20px;
}

.rewards-text .signup-btn {
    font-family: 'Mulish', sans-serif;
    border: 2px solid #3C2415;
    min-width: 180px;
    background-color: transparent;
    border-radius: 0px;
    color: #3C2415;
    font-weight: 700;
    height: 54px;
}

.rewards-text .login-btn {
    font-family: 'Mulish', sans-serif;
    border: 2px solid #3C2415;
    background-color: #3C2415;
    min-width: 180px;
    border-radius: 0px;
    color: #FFF;
    font-weight: 700;
    height: 54px;
    margin-left: 15px;
}

.earn-points-section {
    margin-top: 20px;
}

.ways-to-earn-points h2 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
    text-align: center;
    font-size: 40px;
    text-transform: uppercase;
}

.rewards-system {
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    border: 1px solid rgb(225, 225, 225);
    background-color: rgba(128, 128, 128, 0.1);
}

.system {
   text-align: center;
}

.system-details {
    margin-left: 15px;
}

.system-details h5 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 800;
    margin-bottom: 0px;
}

.system-details p {
    font-family: 'Mulish', sans-serif;
    font-weight: 500;
    margin-bottom: 0px;
    color: grey;
}

.benefit-card {
    padding: 15px 20px;
    border: 1px solid rgb(225, 225, 225);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 5px;
}

.benefit-card .system .system-details {
    margin-left: 10px;
    margin-top: 15px;
}

.benefit-card .system .system-details h5 {
    font-family: 'Inter Tight', sans-serif;
    font-size: 22px;
    font-weight: 700;
}

.benefit-card .system .system-details p {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 5px;
}

.make-purchase-card {
    display: flex;
    align-items: center;
    background-color: #3C2415;
    padding: 20px 50px;
    border-radius: 5px;
}

.make-purchase-card .spend-box {
    background-color: #FFF;
    margin-left: 15px;
    padding: 25px;
    text-align: center;
    border-radius: 50%;
}

.make-purchase-card .spend-box h4 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
    margin-bottom: 0px;
}

.make-purchase-card .spend-box h3 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
    color: #FFCF01;
    margin-bottom: 0px;
    font-size: 35px;
}

.make-purchase-card h2 {
    margin-left: 30px;
    color: #FFF;
    font-size: 80px;
}

.make-purchase-card h2 span {
    text-decoration: none;
    color: #FFCF01;
    margin-left: 5px;
    margin-right: 5px;
}

/* HOW IT WORKS */
.how-it-works {
    text-align: center;
}

.how-it-works h2 {
    font-family: 'Inter Tight', sans-serif;
    font-size: 40px;
    font-weight: 700;
}

.how-it-works .steps .steps-detail h3 {
    font-family: 'Inter Tight', sans-serif;
    font-size: 50px;
    font-weight: 600;
    color: #F15A29;
}

.how-it-works .steps .steps-detail h4 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
    font-size: 30px;
}

.how-it-works .steps .steps-detail h5 {
    font-family: 'Mulish', sans-serif;
    font-weight: 600;
    margin-top: 10px;
}

.redeem-your-discount {
    text-align: center;
}

.redeem-your-discount h2 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
    text-align: center;
    font-size: 40px;
    text-transform: uppercase;
}

.redeem-your-discount h5 {
    font-family: 'Mulish', sans-serif;
    font-weight: 500;
    text-align: center;
}

.benefit-card.new-box {
    box-shadow: none !important;
    border: 0px solid transparent;
    border-radius: 0px;
    border-right: 1px solid rgb(225, 225, 225);
}

.benefit-card.new-box-last {
    box-shadow: none !important;
    border: 0px solid transparent;
    border-radius: 0px;
    border-right: 0px solid rgb(225, 225, 225) !important;
}

.wishlist-heading {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
}

.email-newsletter h2 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-size: 40px;
}

.email-newsletter p {
    font-family: 'Mulish', sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 22px;
}

.form-control-big {
    font-family: 'Mulish', sans-serif;
    border: 1px solid #E2E9E1;
    border-radius: 5px;
    box-shadow: none;
    padding: 15px;
    padding-left: 20px;
    font-size: #161C27;
    width: 100%;
}

.submit-btn-rewards {
    width: 100%;
    font-family: 'Mulish', sans-serif;
    background-color: #3E3EF4;
    height: 100%;
    font-size: 16px;
}

.new-rewards-section .rewards-with-bg {
    background-image: url('../../assets/rewards-banner.webp');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    padding: 120px 100px;
}

.new-rewards-section .rewards-with-bg .left-rewards h2 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
}

.new-rewards-section .rewards-with-bg .left-rewards h5 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
    font-size: 24px;
}